<template>
  <div>
    <member-info :member_id="member_id"></member-info>

    <b-tabs v-model="activeTabIndex">
      <b-tab :title="`Order ปัจจุบัน ( ${order_now.length} )`">
        <b-overlay :show="loading.now">
          <b-card no-body>
            <filter-table :filter_data="order_now" />
          </b-card>
        </b-overlay>
      </b-tab>
      <b-tab :title="`ประวัติการเช่า ( ${order_history.length} )`">
        <b-overlay :show="loading.history">
          <b-card no-body>
            <filter-table :filter_data="order_history" />
          </b-card>
        </b-overlay>
      </b-tab>
      <b-tab :title="`Order Log ( ${order_log.length} )`">
        <b-overlay :show="loading.log">
          <b-card no-body>
            <filter-table :filter_data="order_log" />
          </b-card>
        </b-overlay>
      </b-tab>
    </b-tabs>

    <edit-member-modal :member_id="member_id"></edit-member-modal>
    <change-password-modal :member_id="member_id"></change-password-modal>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BCardText,
  BRow,
  BCol,
  BAvatar,
  BOverlay,
} from "bootstrap-vue";
import MemberInfo from "./component/MemberInfo.vue";
import FilterTable from "../order/component/FilterTable.vue";
import EditMemberModal from "./component/EditMemberModal.vue";
import ChangePasswordModal from "./component/ChangePasswordModal.vue";

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BAvatar,
    MemberInfo,
    FilterTable,
    EditMemberModal,
    ChangePasswordModal,
    BTabs,
    BTab,
    BOverlay,
  },

  data() {
    return {
      member_id: this.$route.params.id,
      order_history: [],
      order_now: [],
      order_log: [],
      activeTabIndex: 0,
      loading: {
        history: false,
        now: false,
        log: false,
      },
    };
  },
  created() {
    this.OrderHistory();
    this.OrderNow();
    this.OrderLog();
  },
  methods: {
    OrderHistory() {
      this.loading.history = true;
      const form = {
        side: "all",
        search: {
          customer_info: this.member_id,
        },
      };
      form.selected_page = 1;
      form.per_page = 100;
      this.$http({
        method: "POST",
        url: `/order/list/filter`,
        data: form,
      }).then((x) => {
        this.order_history = x.data.data;
        this.loading.history = false;
      });
    },
    OrderNow() {
      this.loading.now = true;
      const form = {
        side: "order_now",
        member_id: this.member_id,
      };
      form.selected_page = 1;
      form.per_page = 100;
      this.$http({
        method: "POST",
        url: `/order/list/filter`,
        data: form,
      }).then((x) => {
        this.order_now = x.data.data;
        this.loading.now = false;
      });
    },
    OrderLog() {
      this.loading.log = true;

      const form = {
        side: "order_log",
        member_id: this.member_id,
        is_delete: true,
      };
      form.selected_page = 1;
      form.per_page = 100;
      this.$http({
        method: "POST",
        url: `/order/list/filter`,
        data: form,
      }).then((x) => {
        this.loading.log = false;
        this.order_log = x.data.data;
      });
    },
  },
};
</script>

<style></style>
