<template>
  <div>
    <b-modal
      id="modal-change-password"
      ref="modal-change-password"
      title="เปลี่ยนรหัสผ่าน"
      no-close-on-backdrop
      size="md"
      hide-footer
    >
      <validation-observer ref="changePassword">
        <b-form @submit.prevent="changePassword">
          <!-- ชื่อ -->
          <b-form-group label="รหัสผ่าน">
            <validation-provider #default="{ errors }" name="password">
              <b-form-input v-model="pre.password" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-button variant="success" @click.prevent="validationForm">
            เปลี่ยนรหัสผ่าน
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BModal,
  BButton,
  BFormRadio,
  BAlert,
  BFormTextarea,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BForm,
    BModal,
    BButton,
    BFormRadio,
    ValidationObserver,
    ValidationProvider,
    BAlert,
    BRow,
    BCol,
    BFormTextarea,
  },
  props: ["member_id"],
  data() {
    return {
      pre: {
        password: null,
      },
    };
  },
  methods: {
    showToast(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "แก้ไขข้อมูลสมาชิก",
          icon,
          text: "แก้ไขข้อมูลสมาชิกเรียบร้อยแล้ว",
          variant,
        },
      });
    },
    ChangePassword() {
      this.pre.member_id = this.member_id;
      console.log("this.pre", this.pre);
      this.$http({
        method: "POST",
        url: `/member/change-password`,
        data: this.pre,
      })
        .then((x) => {
          this.$refs["modal-change-password"].hide();
          this.showToast("success", "CheckIcon");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    validationForm() {
      this.$refs.changePassword.validate().then((success) => {
        if (success) {
          this.ChangePassword();
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
