var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modal-edit-member",attrs:{"id":"modal-edit-member","title":"แก้ไขข้อมูลสมาชิก","no-close-on-backdrop":"","size":"lg","hide-footer":""}},[_c('validation-observer',{ref:"editMember"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.editMember($event)}}},[_c('b-form-group',{attrs:{"label":"ชื่อ"}},[_c('validation-provider',{attrs:{"name":"First name","rules":{ required: true, regex: /^[ก-ฮะ-์ํฯ\s]+$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"ภาษาไทยเท่านั้น"},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"นามสกุล"}},[_c('validation-provider',{attrs:{"name":"Last name","rules":{ required: true, regex: /^[ก-ฮะ-์ํฯ\s]+$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"ภาษาไทยเท่านั้น"},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Gen Username"}},[_c('validation-provider',{attrs:{"name":"Gen Username"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.form.gen_username),callback:function ($$v) {_vm.$set(_vm.form, "gen_username", $$v)},expression:"form.gen_username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"เลขที่บัตรประชาชน"}},[_c('validation-provider',{attrs:{"name":"Card ID","rules":"digits:13"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"เฉพาะตัวเลขไม่ต้องเว้นวรรค","maxlength":"13"},model:{value:(_vm.form.card_id),callback:function ($$v) {_vm.$set(_vm.form, "card_id", $$v)},expression:"form.card_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"เบอร์โทรศัพท์มือถือ"}},[_c('validation-provider',{attrs:{"name":"Phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"ไม่ต้องใส่ขีด","maxlength":"10"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Email"}},[_c('validation-provider',{attrs:{"name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('day-month-year',{attrs:{"prop_select":_vm.birth_select,"day_text":'วันเกิด',"year_range":{
              max_year: _vm.this_year - 18,
              min_year: _vm.this_year - 80,
            }},on:{"PickerUpdate":_vm.BirthdayUpdate}}),_c('validation-provider',{attrs:{"name":"Birth Day"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{staticClass:"hidden",model:{value:(_vm.form.birth_day),callback:function ($$v) {_vm.$set(_vm.form, "birth_day", $$v)},expression:"form.birth_day"}}),_c('small',{staticClass:"text-danger"},[_c('div',[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('validation-provider',{attrs:{"name":"Birth Month"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{staticClass:"hidden",model:{value:(_vm.form.birth_month),callback:function ($$v) {_vm.$set(_vm.form, "birth_month", $$v)},expression:"form.birth_month"}}),_c('small',{staticClass:"text-danger"},[_c('div',[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('validation-provider',{attrs:{"name":"Birth Year"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{staticClass:"hidden",model:{value:(_vm.form.birth_year),callback:function ($$v) {_vm.$set(_vm.form, "birth_year", $$v)},expression:"form.birth_year"}}),_c('small',{staticClass:"text-danger"},[_c('div',[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('small',[_vm._v("ผู้เช่าต้องมีอายุมากกว่า 18 ปีเท่านั้น")])],1),_c('b-form-group',{attrs:{"label":"เพศ"}},[_c('validation-provider',{attrs:{"name":"Gender"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-radio',{attrs:{"name":"some-radios","value":"male"},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}},[_vm._v(" ชาย ")]),_c('b-form-radio',{attrs:{"name":"some-radios","value":"female"},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}},[_vm._v(" หญิง ")]),_c('b-form-radio',{attrs:{"name":"some-radios2","value":"other"},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}},[_vm._v(" อื่นๆ ")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"ที่อยู่"}},[_c('validation-provider',{attrs:{"name":"Address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"เลขที่ อาคาร/หมู่บ้าน หมู่ ซอย"},model:{value:(_vm.form.address1),callback:function ($$v) {_vm.$set(_vm.form, "address1", $$v)},expression:"form.address1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"ถนน"}},[_c('validation-provider',{attrs:{"name":"Road"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"ถนน"},model:{value:(_vm.form.road),callback:function ($$v) {_vm.$set(_vm.form, "road", $$v)},expression:"form.road"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('p',[_vm._v("กรอก "),_c('u',[_vm._v("แขวง")]),_vm._v(" หรือ "),_c('u',[_vm._v("เขต")]),_vm._v(" หรือ "),_c('u',[_vm._v("รหัสไปรษณีย์")])]),_c('address-selector',{on:{"addressSelected":_vm.addressSelected}}),(_vm.form.province != null)?_c('b-alert',{staticClass:"mt-1",attrs:{"show":"","variant":"success"}},[_c('div',{staticClass:"alert-body"},[_vm._v(_vm._s(_vm.form.full_address))])]):_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_c('div',{staticClass:"alert-body"},[_vm._v("ยังไม่ได้กรอกข้อมูลที่อยู่")])]),_c('validation-provider',{attrs:{"name":"Zipcode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{staticClass:"hidden",model:{value:(_vm.form.zipcode),callback:function ($$v) {_vm.$set(_vm.form, "zipcode", $$v)},expression:"form.zipcode"}}),_c('small',{staticClass:"text-danger"},[_c('div',[_vm._v(_vm._s(errors[0]))])])]}}])})],1),_c('b-form-group'),_c('b-form-group',{attrs:{"label":"รหัสหลังบัตรประชาชน"}},[_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":"XXX","maxlength":"12"},on:{"input":function($event){return _vm.moveToNextField($event, 2, 3)}},model:{value:(_vm.form.id_laser),callback:function ($$v) {_vm.$set(_vm.form, "id_laser", $$v)},expression:"form.id_laser"}})],1)],1),_c('b-form-group',{attrs:{"label":"Note"}},[_c('b-form-textarea',{attrs:{"id":"event-description"},model:{value:(_vm.form.note_admin),callback:function ($$v) {_vm.$set(_vm.form, "note_admin", $$v)},expression:"form.note_admin"}})],1),_c('b-form-group',{attrs:{"label":"แสดงราคามัดจำ"}},[_c('validation-provider',{attrs:{"name":"blackout"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('div',{staticClass:"demo-inline-spacing mt-0"},[_c('b-form-radio',{attrs:{"name":"some-radios","value":true},model:{value:(_vm.form.deposit_blackout),callback:function ($$v) {_vm.$set(_vm.form, "deposit_blackout", $$v)},expression:"form.deposit_blackout"}},[_vm._v(" ไม่แสดง !! ")]),_c('b-form-radio',{attrs:{"name":"some-radios","value":false},model:{value:(_vm.form.deposit_blackout),callback:function ($$v) {_vm.$set(_vm.form, "deposit_blackout", $$v)},expression:"form.deposit_blackout"}},[_vm._v(" ปกติ ")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('hr'),_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" แก้ไขข้อมูล ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }